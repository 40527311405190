import React from "react";
import { useAuth } from "./AuthProvider";
import { get, includes } from "lodash";

export default function Authorize({ roles, children }: { children: React.ReactNode | React.ReactNode[]; roles: string[] }) {
   const { user } = useAuth();

   if (includes(roles, get(user, "role.name"))) {
      return <>{children}</>;
   }
   return null;
}
