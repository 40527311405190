import React, { useState } from "react";
import { Paginator, PaginatorPageChangeEvent, PaginatorProps } from "primereact/paginator";
import { useSearchParams } from "react-router-dom";
import { classNames } from "services/utilities";

export default function Pagination({ totalRecords = 0, rows = 0, className, ...props }: PaginatorProps) {
   const [params, setParams] = useSearchParams();

   const [first, setFirst] = useState<number>(() => {
      const activePage = parseInt(params.get("page") ?? "0");
      return activePage ? rows * (activePage - 1) : 0;
   });

   const onPageChange = (event: PaginatorPageChangeEvent) => {
      params.set("page", (event.page + 1) as any);
      setParams(params);
      setFirst(event.first);
   };

   return (
      <div className={classNames(className, "card")}>
         <Paginator
            pt={{
               pageButton: { className: "!rounded-full !mx-1" },
               lastPageButton: { className: "!rounded-full !mx-1" },
               firstPageButton: { className: "!rounded-full !mx-1" },
               nextPageButton: { className: "!rounded-full !mx-1" },
               prevPageButton: { className: "!rounded-full !mx-1" },
            }}
            first={first}
            rows={rows}
            totalRecords={totalRecords}
            onPageChange={onPageChange}
            {...props}
         />
      </div>
   );
}
