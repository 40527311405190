import { Blocked, Incorrect, Opened, Rejected, Sent, TotalClicks, Traited, UniqueClicks, Unwanted } from "assets/icons";
import { get } from "lodash";

const icons = {
   traited: <Traited className="h-8 w-8" />,
   sent: <Sent className="h-8 w-8" />,
   opened: <Opened className="h-8 w-8" />,
   totalClicks: <TotalClicks className="h-8 w-8" />,
   uniqueClicks: <UniqueClicks className="h-8 w-8" />,
   rejected: <Rejected className="h-8 w-8" />,
   blocked: <Blocked className="h-8 w-8" />,
   unwanted: <Unwanted className="h-8 w-8" />,
   incorrect: <Incorrect className="h-8 w-8" />,
};

interface Props {
   title: string;
   icon: string;
   number: number | undefined;
}

export default function StatisticsCard({ title, icon, number }: Props) {
   return (
      <div className="border border-primary/40 p-3 rounded-xl">
         <div className="flex  justify-between">
            <span className="text-lg">{title}</span>
            <span>{get(icons, icon)}</span>
         </div>
         <div className="text-center text-3xl font-bold mt-4">{number}</div>
      </div>
   );
}
