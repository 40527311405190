import React from "react";
import { Form, Formik, FormikConfig } from "formik";
import { object, string } from "yup";
import { Link } from "react-router-dom";
import { ReactComponent as EnvelopSvg } from "assets/icons/envolop.svg";
import { ReactComponent as LockSvg } from "assets/icons/lock.svg";
import { RoutesEnum } from "Routes";
import { Input } from "components";
import { useAuth } from "./AuthProvider";
import { Login } from "./types";
import { emailRegex } from "services/utilities";

export default function LoginForm() {
   const { login } = useAuth();
   const formikConfig: FormikConfig<Login> = {
      initialValues: {
         email: "",
         password: "",
      },
      onSubmit: async (values, { setSubmitting }) => {
         await login(values);
         setSubmitting(false);
      },
      validationSchema: object({
         email: string().required("is-required").matches(emailRegex, "is-email"),
         password: string().required(),
      }),
   };

   return (
      <div>
         <h3 className="text-2xl font-semibold">Bienvenu sur votre portail Digisend</h3>
         <p className="text-lg">Entrez vos informations de connexion pour accéder à votre portail</p>
         <Formik {...formikConfig}>
            {({ isValid, isSubmitting }) => (
               <Form>
                  <div className="auth grid gap-3 mt-10">
                     <span className="p-input-icon-left w-full  ">
                        <i aria-label="envelop-icon">
                           <EnvelopSvg className="w-5" />
                        </i>
                        <Input name="email" className=" pl-6 rounded-lg  border-0 bg-grayLight/20 py-3" />
                     </span>
                     <span className="p-input-icon-left w-full  ">
                        <i aria-label="lock-icon">
                           <LockSvg className="w-4" />
                        </i>
                        <Input name="password" className=" pl-6 rounded-lg  border-0 bg-grayLight/20 py-3" type="password" />
                     </span>
                  </div>
                  <div className="mt-1 text-right">
                     <Link to={`/${RoutesEnum.AUTH}/${RoutesEnum.FORGOT_PASSWORD}`} className="text-secondary">
                        Mot de passe oublié?
                     </Link>
                  </div>
                  <div>
                     <button
                        type="submit"
                        disabled={!isValid || isSubmitting}
                        className="bg-secondary text-white font-bold text-black w-full py-3 rounded-lg mt-5 hover:opacity-90 "
                     >
                        Connexion
                     </button>
                  </div>
               </Form>
            )}
         </Formik>
      </div>
   );
}
