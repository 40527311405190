import { RoutesEnum } from "Routes";
import { User } from "./types";
import { get } from "lodash";

export enum Roles {
   SUPER_ADMIN = "super_admin",
   CLIENT_ADMIN = "client_admin",
   CLIENT_MANAGER = "client_manager",
}

export const getHomePath = (user: User) => {
   if (get(user, "role.name") === Roles.SUPER_ADMIN) return "/";
   return `/${RoutesEnum.MYSPACE}/${RoutesEnum.WELCOME}`;
};
