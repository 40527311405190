import React from "react";
import { InputTextProps } from "primereact/inputtext";
import { useField } from "formik";
import { classNames } from "services/utilities";
import ErrorMessage from "./ErrorMessage";

export default function Checkbox({
   name,
   className,
   label,
   hideStar = false,
   ...props
}: InputTextProps & { name: string; label?: string; hideStar?: boolean }) {
   const [field, meta] = useField(name);
   const hasError = meta.error && meta.touched;

   return (
      <>
         <div className="flex gap-1 items-center">
            <input
               disabled={props.disabled}
               id={label}
               className={classNames(
                  "appearance-none border border-secondary w-4 h-4 !rounded inline-block !checked:bg-primary",
                  { "bg-secondary": field.value === props.value },
                  { "has-error": hasError },
                  className
               )}
               {...field}
               value={props.value}
               type={props.type}
               placeholder={props.placeholder}
            />
            {label && (
               <label htmlFor={label} className={classNames("text-semibold inline-block", { "text-danger": hasError })}>
                  {label}
               </label>
            )}
         </div>
         <ErrorMessage hasError={!!hasError} error={meta.error} />
      </>
   );
}
