import { get, set } from "lodash";
import { date, number, object, string } from "yup";
import { Campaign, SmsCampaign } from "./types";
import { User } from "features/auth/types";
import { RoutesEnum } from "Routes";
import { Roles } from "features/auth/authService";

export enum CAMPAIGN_TYPE {
   EMAIL = "email",
   SMS = "sms",
}
export enum CAMPAIGN_STATUS {
   ACTIVE = "active",
   BLOCKED = "blocked",
}

export enum ATTACHMENT_TYPE {
   NONE = "none",
   LINK = "link",
   PDF = "pdf",
}
export const recurrences = [
   {
      label: "Once every 5 mins",
      value: 5,
   },
   {
      label: "Deux fois par jour",
      value: 6 * 60,
   },
   {
      label: "Une fois par jour",
      value: 24 * 60,
   },

   {
      label: "Une fois par semaine",
      value: 168 * 60,
   },
];

export const initialValues: Campaign = {
   id: undefined,
   type: CAMPAIGN_TYPE.EMAIL,
   attachment_type: ATTACHMENT_TYPE.LINK,
   start_date: undefined,
   end_date: undefined,
   recurrence_interval: undefined,
   folder: undefined,
   sender_id: undefined,
   template_id: undefined,
};

export const smsInitialValues: SmsCampaign = {
   id: undefined,
   type: CAMPAIGN_TYPE.SMS,
   attachment_type: ATTACHMENT_TYPE.LINK,
   start_date: undefined,
   end_date: undefined,
   recurrence_interval: undefined,
   folder: undefined,
   text_content: undefined,
   sender_id: undefined,
};
export const validationSchema = object({
   start_date: date().required("Obligatoire"),
   end_date: date().required("Obligatoire"),
   recurrence_interval: number().required("Obligatoire"),
   folder: string().required("Obligatoire"),
   sender_id: number().required("Obligatoire"),
   template_id: string().required("Obligatoire"),
});
export const smsCampaignValidationSchema = object({
   start_date: date().required("Obligatoire"),
   end_date: date().required("Obligatoire"),
   recurrence_interval: number().required("Obligatoire"),
   folder: string().required("Obligatoire"),
   sender_id: number().required("Obligatoire"),
});

export const preparePayload = (payload: Campaign): Campaign => {
   set(payload, "start_date", new Date(payload.start_date as Date).toISOString());
   set(payload, "end_date", new Date(payload.end_date as Date).toISOString());
   return payload;
};
export const prepareInitialValues = (payload?: Campaign): Campaign | SmsCampaign => {
   if (!payload) return {};
   return payload;
};

export function getCampaignRecurrencesUrl({ user, campaign }: { user: User; campaign: Campaign }): string {
   let url = "";
   const userRole = get(user, "role.name");
   if (userRole === Roles.SUPER_ADMIN) {
      url = `/${RoutesEnum.CLIENTS}/${campaign.client_id}/${RoutesEnum.CAMPAIGNS}/${campaign.id}/${RoutesEnum.RECURRENCES}`;
   }
   if (userRole === Roles.CLIENT_ADMIN) {
      url = `/${RoutesEnum.MYSPACE}/${RoutesEnum.CAMPAIGNS}/${campaign.id}/${RoutesEnum.RECURRENCES}`;
   }
   return url;
}

export function getCampaignGeneralStatisticsUrl({ user, campaign, clientId }: { user: User; campaign: Campaign; clientId?: number }): string {
   let url = "";
   const userRole = get(user, "role.name");
   if (userRole === Roles.SUPER_ADMIN) {
      url = `/${RoutesEnum.CLIENTS}/${clientId}/${RoutesEnum.CAMPAIGNS}/${campaign.id}/${RoutesEnum.GENERAL_STATISTICS}`;
   }
   if (userRole === Roles.CLIENT_ADMIN) {
      url = `/${RoutesEnum.MYSPACE}/${RoutesEnum.CAMPAIGNS}/${campaign.id}/${RoutesEnum.GENERAL_STATISTICS}`;
   }
   return url;
}
