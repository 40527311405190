import { replace } from "lodash";
import React, { useMemo } from "react";
import { AppConfig } from "services/appConfig";

interface HTMLImageElementProps {
   src: string;
   alt?: string;
   width?: number;
   height?: number;
   loading?: "eager" | "lazy" | "auto";
   decoding?: "async" | "sync";
   crossOrigin?: "anonymous" | "use-credentials";
   referrerPolicy?: string;
   sizes?: string;
   srcset?: string;
   isMap?: boolean;
   useMap?: string;
   style?: React.CSSProperties;
   className?: string;
}

export default function Img({ src, ...props }: HTMLImageElementProps | any) {
   const path = useMemo(() => {
      return `${AppConfig.apiUrl}/${replace(src, "public", "storage")}`;
   }, [src]);
   return <img {...props} src={path} />;
}
