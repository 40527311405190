import React from "react";
import DataTable from "./DataTable";
import { get } from "lodash";
import { useSearchParams } from "react-router-dom";
import Pagination from "./Pagination";
import { useCrud } from "hooks";
import { DataTableRowClickEvent } from "primereact/datatable";

interface Props {
   resource: string;
   columns: any[];
   defaultFilters?: string[];
   perPage?: number;
   onRowClick?: (e: DataTableRowClickEvent) => any;
}

export default function ResourceListing({ resource, columns, defaultFilters = [], perPage = 8, onRowClick }: Props) {
   const [searchParams] = useSearchParams();
   const filters = [...defaultFilters, `per_page=${perPage}`, searchParams].join("&");
   const { data, isLoading } = useCrud({ url: `${resource}?${filters}` });

   const totalRecords = get(data, "meta.total", 0);
   const rows = get(data, "meta.per_page", 10);

   return (
      <>
         <DataTable onRowClick={onRowClick} loading={isLoading} data={get(data, "data", [])} columns={columns} />
         <Pagination alwaysShow={false} totalRecords={totalRecords} rows={rows} />
      </>
   );
}
