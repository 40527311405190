import React, { useRef } from "react";
import { Menu } from "primereact/menu";
import { ReactComponent as ElippsisVertical } from "assets/icons/ellipsisVertical.svg";
import { useAuth } from "../AuthProvider";

export default function Logout() {
   const { logout } = useAuth();

   const menu = useRef<Menu>(null);
   const items = [
      {
         label: "Déconnecter",

         command: () => {
            logout();
         },
      },
   ];

   return (
      <>
         <button onClick={(e) => menu.current?.toggle(e)} className="!p-1 border-primary/40 border !rounded-full">
            <ElippsisVertical className="h-6" />
         </button>
         <Menu model={items} popup ref={menu} id="popup_menu_right" popupAlignment="right" />
      </>
   );
}
