import ResourceListing from "components/ResourceListing";
import { useApi } from "hooks";
import { set } from "lodash";
import { ColumnProps } from "primereact/column";
import { mutate } from "swr";
import { CLIENT_STATUS } from "../clientService";
import { Client } from "../types";

const ActionBody = (client: Client) => {
   const api = useApi({});
   const unblockClient = async () => {
      set(client, "status", CLIENT_STATUS.ACTIVE);
      await api.put(`/clients/${client.id}`, client);
      mutate((key) => typeof key === "string" && key.startsWith("clients"));
   };
   return (
      <>
         <div className="flex gap-3 items-center justify-end text-sm">
            <button className="text-success" onClick={unblockClient}>
               Débloquer le compte
            </button>
         </div>
      </>
   );
};

export default function BlockedClients() {
   const columns: ColumnProps[] = [
      {
         header: "Client",
         field: "name",
      },
      {
         header: "",
         body: ActionBody,
      },
   ];
   return (
      <div>
         <h1 className="like-butotn-title mb-4">Comptes bloqués</h1>
         <ResourceListing resource="clients" columns={columns} defaultFilters={["status=blocked"]} />
      </div>
   );
}
