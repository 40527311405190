import React, { useState } from "react";
import { Form, Formik, FormikConfig } from "formik";
import { object, ref, string } from "yup";
import { Input } from "components";
import { useApi } from "hooks";
import { Link, useSearchParams } from "react-router-dom";
import { RoutesEnum } from "Routes";
import { ResetPassword } from "./types";

export default function ResetPasswordForm() {
   const [submittedSuccessfully, setSubmittedSuccessfully] = useState(false);
   const api = useApi({});
   const [params] = useSearchParams();
   const formikConfig: FormikConfig<ResetPassword> = {
      initialValues: {
         password: "",
         password_confirmation: "",
         token: params.get("token") ?? "",
         email: params.get("email") ?? "",
      },
      onSubmit: async (values) => {
         const res = await api.put("/auth/reset-password", { ...values });
         if (!res) return;
         setSubmittedSuccessfully(true);
      },
      validationSchema: object({
         password: string().required("is-required"),
         password_confirmation: string()
            .oneOf([ref("password")], "password-must-match")
            .required("is-required"),
         token: string().required("is-required"),
      }),
   };

   return (
      <div>
         {submittedSuccessfully ? (
            <div>
               <h3 className="text-xl md:text-2xl mb-3 font-bold">Password changed successfully</h3>
               <p className="mb-4">You click the following link and try to login with the new password</p>
               <Link to={`/${RoutesEnum.AUTH}/${RoutesEnum.LOGIN}`}>Login</Link>
            </div>
         ) : (
            <>
               <div>
                  <h3 className="text-2xl font-semibold">Bienvenu sur votre portail Digisend</h3>
                  <p className="text-lg">Entrez vos informations de connexion pour accéder à votre portail</p>
               </div>
               <Formik {...formikConfig}>
                  {({ isValid, isSubmitting }) => (
                     <Form>
                        <div className="auth grid gap-3 mt-10">
                           <Input name="password" className="rounded-lg border-0 bg-grayLight/20 py-3" placeholder="New password" />
                           <Input name="password_confirmation" className="rounded-lg border-0 bg-grayLight/20 py-3" placeholder="Retype new password" />
                        </div>

                        <div className="mt-3">
                           <button
                              type="submit"
                              disabled={!isValid || isSubmitting}
                              className="bg-secondary text-white font-bold text-black w-full py-3 rounded-lg mt-5 hover:opacity-90"
                           >
                              Submit
                           </button>
                        </div>
                     </Form>
                  )}
               </Formik>
            </>
         )}
      </div>
   );
}
