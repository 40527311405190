import React from "react";
import { useCrud } from "hooks";
import { Link, NavLink, Outlet, useParams } from "react-router-dom";
import { Campaign } from "../types";
import { RoutesEnum } from "Routes";
import { Authorize } from "features/auth";
import { Roles } from "features/auth/authService";
import { getCampaignGeneralStatisticsUrl, getCampaignRecurrencesUrl } from "../campaignService";
import { useAuth } from "features/auth/AuthProvider";

export default function Layout() {
   const { campaignId } = useParams();
   const { clientId } = useParams();
   const { user } = useAuth();
   const { data: campaignData, isLoading, error } = useCrud<Campaign>({ url: `/campaigns/${campaignId}` });
   const campaign: Campaign | undefined = campaignData?.data as Campaign;

   return (
      <>
         {error && "Error..."}
         {!isLoading && (
            <div className="mb-4 pt-3">
               <div className="flex items-center justify-between pb-2">
                  <h2 className=" md:text-lg font-semibold">{campaign.ref}</h2>
                  <div className="flex gap-3">
                     <Link
                        to={clientId ? `/${RoutesEnum.CLIENTS}/${clientId}/${RoutesEnum.CAMPAIGNS}` : `/${RoutesEnum.MYSPACE}/${RoutesEnum.CAMPAIGNS}`}
                        relative="path"
                        className="text-success"
                     >
                        Retour
                     </Link>
                  </div>
               </div>
               <div className="flex gap-8 pb-3">
                  <Authorize roles={[Roles.SUPER_ADMIN]}>
                     <NavLink
                        className={({ isActive }) => "text-center text-sm hover:underline text-primary " + (isActive ? "font-bold" : "")}
                        to={`/${RoutesEnum.CLIENTS}/${clientId}/${RoutesEnum.CAMPAIGNS}/${campaign.id}/${RoutesEnum.EDIT}`}
                        end
                     >
                        Paramètres
                     </NavLink>
                  </Authorize>
                  <NavLink
                     className={({ isActive }) => "text-center text-sm hover:underline text-primary " + (isActive ? "font-bold" : "")}
                     to={getCampaignRecurrencesUrl({ user: user as any, campaign })}
                  >
                     Détail par récurrence
                  </NavLink>
                  <NavLink
                     className={({ isActive }) => "text-center text-sm hover:underline text-primary " + (isActive ? "font-bold" : "")}
                     to={getCampaignGeneralStatisticsUrl({ user: user as any, campaign, clientId: clientId as any })}
                  >
                     Statistiques générales
                  </NavLink>
               </div>
               <hr />
            </div>
         )}
         <div>
            <Outlet />
         </div>
      </>
   );
}
