import { RoutesEnum } from "Routes";
import { useAuth } from "features/auth/AuthProvider";
import { Roles } from "features/auth/authService";
import { useCrud } from "hooks";
import { get } from "lodash";
import { NavLink, Outlet, useParams } from "react-router-dom";
import { formatDate } from "services/utilities/date";

export default function RecurrenceLayout() {
   const { campaignId, clientId, recurrenceId } = useParams();
   const { data: recurrenceDetails, isLoading } = useCrud({ url: `/campaign-recurrences/${recurrenceId}` });
   const { user } = useAuth();

   function getStatisticsUrl(): string {
      let url = "";
      const userRole = get(user, "role.name");
      if (userRole === Roles.SUPER_ADMIN) {
         url = `/${RoutesEnum.CLIENTS}/${clientId}/${RoutesEnum.CAMPAIGNS}/${campaignId}/${RoutesEnum.RECURRENCES}/${recurrenceId}/${RoutesEnum.STATISTICS}`;
      }
      if (userRole === Roles.CLIENT_ADMIN) {
         url = `/${RoutesEnum.MYSPACE}/${RoutesEnum.CAMPAIGNS}/${campaignId}/${RoutesEnum.RECURRENCES}/${recurrenceId}/${RoutesEnum.STATISTICS}`;
      }
      return url;
   }

   function getRecipientStatisticsUrl(): string {
      let url = "";
      const userRole = get(user, "role.name");
      if (userRole === Roles.SUPER_ADMIN) {
         url = `/${RoutesEnum.CLIENTS}/${clientId}/${RoutesEnum.CAMPAIGNS}/${campaignId}/${RoutesEnum.RECURRENCES}/${recurrenceId}/${RoutesEnum.RECIPIENTS}`;
      }
      if (userRole === Roles.CLIENT_ADMIN) {
         url = `/${RoutesEnum.MYSPACE}/${RoutesEnum.CAMPAIGNS}/${campaignId}/${RoutesEnum.RECURRENCES}/${recurrenceId}/${RoutesEnum.RECIPIENTS}`;
      }
      return url;
   }

   return (
      <div>
         <p className="">
            <span className="rounded-xl px-4 py-2 bg-primary text-white inline-block mr-3">
               {isLoading ? "" : formatDate(get(recurrenceDetails, "data.date_executed") as any, "dd.MM.yyyy ' à ' HH:mm")}
            </span>
            <span>{get(recurrenceDetails, "data.ref")}</span>
         </p>
         <div className="flex gap-3 py-3">
            <NavLink
               className={({ isActive }) => "rounded-md px-5 py-1 text-primary border border-transparent " + (isActive ? "!border-primary" : "")}
               to={getStatisticsUrl()}
               end
            >
               Statistiques
            </NavLink>
            <NavLink
               className={({ isActive }) => "rounded-md px-5 py-1 text-primary border border-transparent " + (isActive ? "!border-primary" : "")}
               to={getRecipientStatisticsUrl()}
            >
               Destinataires
            </NavLink>
         </div>
         <hr />
         <div>
            <Outlet />
         </div>
      </div>
   );
}
