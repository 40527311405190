import ResourceListing from "components/ResourceListing";
import { ColumnProps } from "primereact/column";
import { Campaign } from "../types";
import { useApi } from "hooks";
import { set } from "lodash";
import { CAMPAIGN_STATUS } from "../campaignService";
import { mutate } from "swr";

export default function BlockedCampaigns() {
   const ActionBody = (row: Campaign) => {
      const api = useApi({});
      const unblock = async () => {
         set(row, "status", CAMPAIGN_STATUS.ACTIVE);
         await api.put(`/campaigns/${row.id}`, row);
         mutate((key) => typeof key === "string" && key.startsWith("campaigns"));
      };

      return (
         <>
            <div className="flex gap-3 items-center justify-end text-sm">
               <button className="text-success" onClick={unblock}>
                  Débloquer la campagne
               </button>
            </div>
         </>
      );
   };
   const columns: ColumnProps[] = [
      {
         header: "Campaign",
         field: "ref",
      },
      {
         header: "",
         body: ActionBody,
      },
   ];
   return (
      <div>
         <h1 className="like-butotn-title mb-4">Campagnes bloquées</h1>
         <ResourceListing resource="campaigns" columns={columns} defaultFilters={["status=blocked"]} />
      </div>
   );
}
