import { useMemo, useState } from "react";
import { ApiService } from "services/networking";
import useSwr, { SWRConfiguration } from "swr";
import useApi from "./useApi";

interface UseCrud {
   url: string;
   swrOptions?: SWRConfiguration;
   otherOptions?: OtherOptions;
}

interface OtherOptions {
   shouldFetch?: boolean;
}

// const fetcher = (url: string) => ApiService.get(url).then((res) => res.data);

export default function useCrud<T extends { id?: number }>({ url, swrOptions, otherOptions = { shouldFetch: true } }: UseCrud) {
   const defaultSwrOptions: SWRConfiguration = {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      errorRetryCount: 2,
      errorRetryInterval: 1000,
   };

   swrOptions = {
      ...defaultSwrOptions,
      ...swrOptions,
   };
   const api = useApi({});
   const fetcher = (url: string) => api.get(url).then((res) => res.data);
   const { data, isLoading, mutate, isValidating, error } = useSwr<{ data: T[] | T; meta: any }>(otherOptions?.shouldFetch ? url : null, fetcher, swrOptions);

   const [isUpdating, setIsUpdating] = useState(false);

   // if the url is for example /posts?data=..., the CUDUrl will be /posts
   const CUDUrl = useMemo(() => {
      return url.includes("?") ? url.split("?")[0] : url;
   }, [url]);

   // Create a function for optimistic updates when creating a resource
   const createResource = async (newResource: T) => {
      setIsUpdating(true);
      // Optimistically update the data
      mutate((prevData: { data: T[]; meta: any } | undefined) => {
         console.log("prev data", prevData);
         if (!prevData) return prevData;
         const newData = [...prevData.data, newResource];
         return { ...prevData, data: newData };
      }, false);

      // Perform the actual API request
      try {
         await ApiService.post(CUDUrl, newResource);

         // Revalidate the data to get the latest
         mutate();
      } catch (error) {
         // Handle the error
         // You might want to rollback the optimistic update on error
         // and display an error message to the user
         console.error("Error creating resource:", error);
         // Revert the optimistic update
         mutate();
      }
      setIsUpdating(false);
   };

   // Create a function for updating a resource
   const updateResource = async (updatedResource: T) => {
      setIsUpdating(true);
      // Optimistically update the data
      mutate((prevData: { data: T[]; meta: any } | undefined) => {
         if (!prevData) return prevData;
         const updatedIndex = prevData.data.findIndex((item) => item.id === updatedResource.id);
         if (updatedIndex === -1) return prevData;
         const newData = [...prevData.data];
         newData[updatedIndex] = updatedResource;
         return { ...prevData, data: newData };
      }, false);

      // Perform the actual API request
      try {
         await ApiService.put(`${CUDUrl}/${updatedResource.id}`, updatedResource);

         // Revalidate the data to get the latest
         mutate();
      } catch (error) {
         // Handle the error
         // You might want to rollback the optimistic update on error
         // and display an error message to the user
         console.error("Error updating resource:", error);
         // Revert the optimistic update
         mutate();
      }
      setIsUpdating(false);
   };

   // Create a function for deleting a resource
   const deleteResource = async (resourceId: number) => {
      setIsUpdating(true);
      // Optimistically update the data
      mutate((prevData: { data: T[] } | undefined) => {
         if (!prevData) return prevData;
         const newData = prevData.data.filter((item) => item.id !== resourceId);
         return { ...prevData, data: newData };
      }, false);

      // Perform the actual API request
      try {
         await ApiService.delete(`${CUDUrl}/${resourceId}`);

         // Revalidate the data to get the latest
         mutate();
      } catch (error) {
         // Handle the error
         // You might want to rollback the optimistic update on error
         // and display an error message to the user
         console.error("Error deleting resource:", error);
         // Revert the optimistic update
         mutate();
      }
      setIsUpdating(false);
   };

   return {
      data,
      isLoading,
      mutate,
      isValidating,
      createResource,
      updateResource,
      deleteResource,
      isUpdating,
      error,
   };
}
