import React from "react";

export default function Footer() {
   const currentYear = new Date().getFullYear();
   return (
      <div className="p-4 text-center bg-primaryLight">
         <p className="text-white">Digisend by Tragem {currentYear} - Tous droits reservés</p>
      </div>
   );
}
