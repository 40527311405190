import React from "react";
import { useCrud } from "hooks";
import { Link, NavLink, Outlet, useParams } from "react-router-dom";
import { Client } from "../types";
import { RoutesEnum } from "Routes";

export default function Layout() {
   const { clientId } = useParams();
   const { data: clientData, isLoading, error } = useCrud<Client>({ url: `/clients/${clientId}` });
   const client: Client | undefined = clientData?.data as Client;

   return (
      <>
         {error && "Error..."}
         {!isLoading && (
            <div className="mb-4">
               <div className="flex items-center justify-between pb-2">
                  <h1 className="text-xl md:text-2xl font-semibold">{client.name}</h1>
                  <div className="flex gap-3">
                     <Link to={`/${RoutesEnum.CLIENTS}/${client.id}/${RoutesEnum.USERS}`} className="text-success">
                        Users
                     </Link>
                     <Link to={`/${RoutesEnum.CLIENTS}/${client.id}/${RoutesEnum.SENDERS}`} className="text-success">
                        Senders
                     </Link>
                     <Link to={`/${RoutesEnum.CLIENTS}/${client.id}/${RoutesEnum.EDIT}`} className="text-success">
                        Modifier compte
                     </Link>
                  </div>
               </div>
               <hr />
               <div className="flex gap-8 py-3">
                  <NavLink
                     className={({ isActive }) => "text-center text-sm hover:underline md:text-base text-primary " + (isActive ? "underline" : "")}
                     to={`/${RoutesEnum.CLIENTS}/${client.id}/${RoutesEnum.CAMPAIGNS}`}
                     end
                  >
                     Voir campagnes
                  </NavLink>
                  <NavLink
                     to={`/${RoutesEnum.CLIENTS}/${client.id}/${RoutesEnum.CAMPAIGNS}/${RoutesEnum.NEW}/email`}
                     className={({ isActive }) => "text-center text-sm hover:underline md:text-base text-primary " + (isActive ? "underline" : "")}
                  >
                     Créer une campagne E-mail
                  </NavLink>
                  <NavLink
                     to={`/${RoutesEnum.CLIENTS}/${client.id}/${RoutesEnum.CAMPAIGNS}/${RoutesEnum.NEW}/sms`}
                     className={({ isActive }) => "text-center text-sm hover:underline md:text-base text-primary " + (isActive ? "underline" : "")}
                  >
                     Créer une campagne SMS
                  </NavLink>
               </div>
               <hr />
            </div>
         )}
         <div>
            <Outlet />
         </div>
      </>
   );
}
