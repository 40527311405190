import { DataTableProps, DataTable as PRDataTable } from "primereact/datatable";
import { Column } from "primereact/column";

interface Props {
   isLoading?: boolean;
   data: any;
   columns: any[];
}
export default function DataTable({ isLoading, data, onRowClick, columns, ...props }: DataTableProps<any> & Props) {
   return (
      <PRDataTable
         {...(props as any)}
         selectionMode="single"
         emptyMessage={isLoading ? "Loading..." : "Aucun élément trouvé"}
         className="border border-primary/30 rounded-md overflow-hidden"
         value={data}
         tableStyle={{ minWidth: "50rem" }}
         onRowClick={onRowClick}
      >
         {columns.map((col) => (
            <Column {...col}></Column>
         ))}
      </PRDataTable>
   );
}
