import { RoutesEnum } from "Routes";
import { Input, Select, Textarea } from "components";
import { Form as FForm, Formik, FormikConfig, useFormikContext } from "formik";
import { useApi, useImmutable } from "hooks";
import { get, isEqual, slice } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import {
   CAMPAIGN_STATUS,
   smsInitialValues as defaultValues,
   prepareInitialValues,
   preparePayload,
   recurrences,
   smsCampaignValidationSchema,
} from "../campaignService";
import { Campaign, SmsCampaign } from "../types";
import { Panel } from "primereact/panel";

export default function EmailForm({ initialValues }: { initialValues?: Campaign }) {
   const api = useApi({});
   const { clientId } = useParams();
   const navigate = useNavigate();

   const formikConfig: FormikConfig<SmsCampaign> = {
      initialValues: { ...defaultValues, client_id: clientId as any, ...prepareInitialValues(initialValues) } as SmsCampaign,
      onSubmit: async (values) => {
         const payload = preparePayload({ ...values });
         const { id } = payload;
         const res = id ? await api.put(`/campaigns/${id}`, payload) : await api.post("/campaigns", payload);
         if (res) {
            navigate(`/${RoutesEnum.CLIENTS}/${clientId}/${RoutesEnum.CAMPAIGNS}`);
         }
      },
      validationSchema: smsCampaignValidationSchema,
   };
   return (
      <>
         <div className="mt-8">
            <Formik {...formikConfig}>
               <TheForm initialValues={initialValues} />
            </Formik>
         </div>
      </>
   );
}

function TheForm(props: any) {
   const { clientId } = useParams();

   const { setFieldValue, isSubmitting, values, submitForm } = useFormikContext<Campaign>();
   const theRecurrences = slice(recurrences, process.env.NODE_ENV === "development" ? 0 : 1);

   const { data: sendersData } = useImmutable(`/senders?client_id=${clientId}`);
   const senders = sendersData?.data ?? [];

   const isEditMode = !!get(values, "id");
   const isBlocked = !!isEqual(get(props.initialValues, "status"), CAMPAIGN_STATUS.BLOCKED);

   const blockCampaign = () => {
      setFieldValue("status", CAMPAIGN_STATUS.BLOCKED);
      submitForm();
   };
   const activateCampaign = () => {
      setFieldValue("status", CAMPAIGN_STATUS.ACTIVE);
      submitForm();
   };

   return (
      <FForm>
         <div className="grid gap-3 md:grid-cols-2 md:gap-5 !gap-x-8">
            <div>
               <Input disabled={isEditMode} name="start_date" type="datetime-local" label="Date et heure de début de la campagne" />
            </div>
            <div>
               <Input name="end_date" type="datetime-local" label="Date et heure de fin de la campagne" />
            </div>
            <div>
               <Select disabled={isEditMode} name="recurrence_interval" label="Récurrence" options={theRecurrences} optionLabel="label" optionValue="value" />
            </div>
            <div>
               <Input disabled={isEditMode} name="folder" label="Lien du répértoire" />
            </div>
            <div>
               <Select
                  disabled={isEditMode}
                  name="sender_id"
                  label="Numéro de téléphone d’envoi"
                  options={senders}
                  optionLabel="phone_number"
                  optionValue="id"
               />
            </div>

            <div className="grid gap-5 md:col-span-2">
               <Panel header="Plus de détails" toggleable collapsed>
                  <div className="grid gap-5">
                     <div>
                        <Input name="config.pin_input_placeholder" label="Input placeholder" hideStar className="w-full" />
                     </div>
                     <div>
                        <Textarea rows={8} name="config.greeting_html" label="Code HTML" hideStar />
                     </div>
                  </div>
               </Panel>
            </div>

            <div className="md:col-span-2">
               <div className="w-full flex items-center justify-end gap-4">
                  {isEditMode && !isBlocked && (
                     <button disabled={isSubmitting} onClick={blockCampaign} type="button" className="basic  text-danger border border-danger">
                        Bloquer
                     </button>
                  )}
                  {isEditMode && isBlocked && (
                     <button disabled={isSubmitting} onClick={activateCampaign} type="button" className="basic  text-success border border-success">
                        Débloquer
                     </button>
                  )}
                  <button disabled={isSubmitting} type="submit" className="basic primary">
                     {isEditMode ? "Modifier" : "Confirmer la création"}
                  </button>
               </div>
            </div>
         </div>
      </FForm>
   );
}
