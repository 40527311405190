import React from "react";
import { useAuth } from "./AuthProvider";
import { Navigate } from "react-router-dom";
import { Cookie } from "hooks";
import UserHomePage from "./UserHomePage";
import { get, includes } from "lodash";

export default function ProtectedRoute({ children, roles }: { children: React.ReactNode | React.ReactNode[]; roles?: string[] }) {
   const { user } = useAuth();
   const cookie = Cookie("token");

   if (!user) {
      cookie.remove();
      return <Navigate to={"/auth/login"} />;
   }
   if (roles?.length && !includes(roles, get(user, "role.name"))) {
      return <UserHomePage />;
   }

   return <>{children}</>;
}
