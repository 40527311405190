import React from "react";
import { Outlet } from "react-router-dom";
import { ReactComponent as AuthBg } from "assets/images/svg1.svg";
import logo from "assets/images/logo.png";

export default function AuthLayout() {
   return (
      <div className="container mx-auto">
         <div className="grid min-h-[100dvh] lg:grid-cols-5">
            <div className="p-10 text-center lg:col-span-2">
               <img src={logo} className="mx-auto py-10 md:py-20 w-40 md:w-60 max-w-full" alt="" />
               <p className="max-w-[400px] md:text-lg mx-auto text-primary font-semibold">
                  Votre solution de référence pour l’envoi de vos campagnes SMS et e-mail
               </p>
               <div className="py-10  hidden lg:block ">
                  <AuthBg className="!w-[60%] mx-auto" />
               </div>
            </div>
            <div className="lg:col-span-3">
               <div className="flex flex-col gap-4 items-center justify-center h-full p-2  ">
                  <div className="grow">
                     <div className="flex h-full items-center">
                        <Outlet />
                     </div>
                  </div>
                  <p className="text-sm text-grayLight py-10">Digisend by Tragem 2023 - Tous droits reservés</p>
               </div>
            </div>
         </div>
      </div>
   );
}
