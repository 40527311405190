import React, { useState } from "react";
import { Form, Formik, FormikConfig } from "formik";
import { object, string } from "yup";
import { Input } from "components";
import { ForgotPassword } from "./types";
import { emailRegex } from "services/utilities";
import { useApi } from "hooks";
import { ReactComponent as EnvelopSvg } from "assets/icons/envolop.svg";

export default function ForgotPasswordForm() {
   const [submittedSuccessfully, setSubmittedSuccessfully] = useState(false);
   const api = useApi({});
   const formikConfig: FormikConfig<ForgotPassword> = {
      initialValues: {
         email: "",
         host: `${window.location.origin}`,
      },
      onSubmit: async (values) => {
         const res = await api.post("/auth/forgot-password", { ...values });
         if (!res) return;
         setSubmittedSuccessfully(true);
      },
      validationSchema: object({
         email: string().required("is-required").matches(emailRegex, "is-email"),
         host: string().required("is-required"),
      }),
   };

   return (
      <div>
         {submittedSuccessfully ? (
            <div>
               <h3 className="text-xl md:text-2xl mb-3 font-bold">A link was sent</h3>
               <p>Please check your email for further instructions</p>
            </div>
         ) : (
            <>
               <div>
                  <h3 className="text-2xl font-semibold">Bienvenu sur votre portail Digisend</h3>
                  <p className="text-lg">Entrez vos informations de connexion pour accéder à votre portail</p>
               </div>
               <Formik {...formikConfig}>
                  {({ isValid, isSubmitting }) => (
                     <Form>
                        <div className="auth grid gap-3 mt-10">
                           <span className="p-input-icon-left w-full  ">
                              <i aria-label="envelop-icon">
                                 <EnvelopSvg className="w-5" />
                              </i>
                              <Input name="email" className=" pl-6 rounded-lg  border-0 bg-grayLight/20 py-3" />
                           </span>
                        </div>

                        <div>
                           <button
                              type="submit"
                              disabled={!isValid || isSubmitting}
                              className="bg-secondary text-white font-bold text-black w-full py-3 rounded-lg mt-5 hover:opacity-90"
                           >
                              Submit
                           </button>
                        </div>
                     </Form>
                  )}
               </Formik>
            </>
         )}
      </div>
   );
}
