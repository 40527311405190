import React, { useState } from "react";
import { FileUpload, FileUploadHandlerEvent } from "primereact/fileupload";
import { useApi } from "hooks";
import { ReactComponent as Trash } from "assets/icons/trash.svg";
// import { ReactComponent as ImageSvg } from "assets/icons/image.svg";
import { useField } from "formik";
import ErrorMessage from "./form/ErrorMessage";
import { classNames } from "primereact/utils";
import { ProgressBar } from "primereact/progressbar";
import Img from "./Img";

interface Props {
   name: string;
   label?: string;
   hideStar?: boolean;
}
export default function ImageUpload({ name, label, hideStar }: Props) {
   const api = useApi({});
   const [loading, setLoading] = useState(false);

   const [field, meta, helpers] = useField(name);
   const hasError = meta.error && meta.touched;

   const onUpload = (data: any) => {
      helpers.setValue(data.path);
   };

   const onDelete = (data: any) => {
      helpers.setValue(null);
   };

   const uploadHandler = (ev: FileUploadHandlerEvent) => {
      setLoading(true);
      ev.files.forEach((element) => {
         const formData = new FormData();
         formData.append("file", ev.files[0]);
         api.post("/upload", formData)
            .then((response) => {
               onUpload(response.data);
            })
            .catch((error) => {
               console.error(error);
            })
            .finally(() => {
               setLoading(false);
            });
      });
   };
   const deleteFile = (file: any) => {
      setLoading(true);
      api.delete(`/files?filename=${file}`)
         .then((response) => {
            onDelete(response.data);
         })
         .catch((error) => {
            onUpload({ path: null });
            console.error(error);
         })
         .finally(() => {
            setLoading(false);
         });
   };

   const EmptyTemplate = () => {
      return (
         <div className={classNames("p-3 bg-grayLight/20 rounded border-0", { "has-error border-none": hasError })}>
            <div className=" rounded h-32 flex items-center justify-center">
               <FileUpload
                  mode="basic"
                  customUpload
                  uploadHandler={uploadHandler}
                  name="demo[]"
                  url="/api/upload"
                  accept="image/*"
                  onUpload={onUpload}
                  auto
                  chooseLabel="Browse"
                  pt={{ chooseIcon: { className: "hidden" }, chooseButtonLabel: { className: "!text-white block !bg-red-300" } }}
               />
            </div>
         </div>
      );
   };
   const ItemTemplate = () => {
      return (
         <div className="p-3 bg-grayLight/20 border-0 rounded-lg ">
            <div className="flex rounded shadow border bg-white">
               <div className="flex align-items-center">
                  <Img src={field.value} className=" h-32 object-cover rounded" />
               </div>
               <button type="button" className="p-button-outlined p-button-rounded p-button-danger ml-auto mr-4" onClick={() => deleteFile(field.value)}>
                  <Trash className="w-5 text-danger" />
               </button>
            </div>
         </div>
      );
   };

   return (
      <div>
         {label && (
            <label htmlFor={name} className={classNames("text-semibold mb-1 inline-block", { "text-danger": hasError })}>
               {label}
               {!hideStar && "*"}
            </label>
         )}
         {loading && <ProgressBar mode="indeterminate" style={{ height: "4px" }}></ProgressBar>}
         {field.value ? <ItemTemplate /> : <EmptyTemplate />}
         <ErrorMessage hasError={!!hasError} error={meta.error} />
      </div>
   );
}
