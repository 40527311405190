import React, { createContext, useContext, useState } from "react";
import { useApi, Cookie, useMountEffect } from "hooks";
import { Login, User } from "./types";
import { useNavigate } from "react-router-dom";
import { useToast } from "features/toast";
import { RoutesEnum } from "Routes";
import { get } from "lodash";
import { axiosInstanceWithoutPrefix } from "services/networking/axiosInstance";
import Logo from "assets/images/logo.png";
import { getHomePath } from "./authService";

interface IAuthContext {
   user: null | User;
   loading: boolean;
   login: (val: Login) => Promise<any>;
   logout: () => Promise<any>;
}

const AuthContext = createContext<IAuthContext>({
   user: null,
   loading: false,
   login: async () => {
      // This is intentional
   },
   logout: async () => {
      // This is intentional
   },
});

export const useAuth = () => {
   return useContext(AuthContext);
};

export default function AuthProvider({ children }: { children: React.ReactNode | React.ReactNode[] }) {
   const navigate = useNavigate();
   const [user, setUser] = useState<User | null>(null);
   const cookie = Cookie("token");
   const [loading, setLoading] = useState(true);
   const api = useApi({ showErrorToast: false, returnError: true });

   const { show } = useToast();

   const login = async (values: Login) => {
      return api
         .post("auth/login", values)
         .then((res) => {
            if (res) {
               setUser(res.data.user);
               cookie.set(res.data.token);
               show({ summary: "Connecté" });
               window.location.replace(getHomePath(res.data.user));
            }
         })
         .catch((error) => {
            show({
               summary: "Error login",
               severity: "error",
               detail: get(error, "response.data.message", "Quelque chose s'est mal passé, veuillez réessayer plus tard"),
            });
         });
   };
   const logout = async () => {
      return api
         .get("auth/logout")
         .then((res) => {
            if (res) {
               setUser(null);
               cookie.remove();
               navigate(`/${RoutesEnum.HOME}`);
            }
         })
         .catch((error) => {
            show({
               summary: "Error",
               severity: "error",
               detail: get(error, "response.data.message", "Quelque chose s'est mal passé, veuillez réessayer plus tard"),
            });
         });
   };

   useMountEffect(() => {
      // fetch and set the user depending on the token form the cookie session and set loading to false
      axiosInstanceWithoutPrefix
         .get("/sanctum/csrf-cookie")
         .then((res) => {
            api.get("/auth/account")
               .then((res) => {
                  if (res) {
                     setUser(res.data);
                  }
               })
               .catch((err) => console.log(err))
               .finally(() => {
                  setLoading(false);
               });
         })
         .catch((err) => {
            setLoading(false);
            console.log(err);
         });
   });

   if (loading)
      return (
         <>
            <div className="h-screen grid place-items-center">
               <img src={Logo} className="w-40" alt="" />
            </div>
         </>
      );

   return <AuthContext.Provider value={{ user, loading, login, logout }}>{children}</AuthContext.Provider>;
}
