import React from "react";
import { classNames } from "services/utilities";
import { useField } from "formik";
import ErrorMessage from "./ErrorMessage";

interface Props {
   name: string;
   placeholder?: string;
   options: any[];
   label?: string;
   optionLabel?: string;
   optionValue?: string;
   className?: string;
   required?: boolean;
   hideFirstEmptyOption?: boolean;
   hideStar?: boolean;
   disabled?: boolean;
}
export default function Select({ label, name, options, optionLabel, optionValue, className, hideStar = false, required, ...props }: Props) {
   const [field, meta, helpers] = useField(name);
   const hasError = meta.error && meta.touched;

   return (
      <>
         {label && (
            <label htmlFor={name} className={classNames("text-semibold mb-1 inline-block", { "text-danger": hasError })}>
               {label}
               {!hideStar && "*"}
            </label>
         )}
         <select
            {...field}
            {...props}
            onFocus={(e) => helpers.setValue(e.target.value)}
            onBlur={() => {
               helpers.setTouched(true);
            }}
            className={classNames(
               "w-full p-dropdown py-[7px] px-2 focus:bg-primary/[0.16] !focus:border-primary text-sm md:text-base outline-none",
               { "has-error": hasError },
               className
            )}
         >
            <option></option>
            {options.map((item) => (
               <option key={JSON.stringify(item)} value={optionValue ? item[optionValue] : item}>
                  {optionLabel ? item[optionLabel] : item}
               </option>
            ))}
         </select>
         <ErrorMessage hasError={!!hasError} error={meta.error} />
      </>
   );
}
