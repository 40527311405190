import { useToast } from "features/toast";
import axiosInstance from "services/networking/axiosInstance";
import { Cookie } from "hooks";
import { get } from "lodash";

interface Props {
   showErrorToast?: boolean;
   returnError?: boolean;
}
export default function useApi({ showErrorToast = true, returnError = false }: Props) {
   const { show, setLoading } = useToast();
   const cookie = Cookie("token");
   axiosInstance.interceptors.response.use(
      function (response) {
         setLoading(false);
         // Any status code that lie within the range of 2xx cause this function to trigger
         // Do something with response data
         return response;
      },
      function (error) {
         setLoading(false);

         // Any status codes that falls outside the range of 2xx cause this function to trigger
         // Do something with response error
         if (showErrorToast) {
            show({
               severity: "error",
               summary: "Error",
               detail: get(error, "response.data.message", "Quelque chose s'est mal passé, veuillez réessayer plus tard"),
            });
         }
         if (returnError) {
            return Promise.reject(error);
         }
      }
   );
   axiosInstance.interceptors.request.use(
      function (config) {
         // Do something before request is sent
         setLoading(true);
         config.headers.set("Authorization", `Bearer ${cookie.value}`);
         return config;
      },
      function (error) {
         // Do something with request error
         return Promise.reject(error);
      }
   );

   return axiosInstance;
}
