import ResourceListing from "components/ResourceListing";
import { useAuth } from "features/auth/AuthProvider";
import { ColumnProps } from "primereact/column";
import { DataTableRowClickEvent } from "primereact/datatable";
import { useNavigate, useParams } from "react-router-dom";
import { formatDate } from "services/utilities/date";
import { getCampaignRecurrencesUrl } from "../campaignService";
import Filters from "./Filters";

interface Props {
   isClientListing?: boolean;
}
export default function Listing({ isClientListing = true }: Props) {
   const itemsPerPage = 10;
   const { clientId } = useParams();
   const navigate = useNavigate();
   const { user } = useAuth();

   let filters: string[] = [`client_id=${clientId}`, `per_page=${itemsPerPage}`, "sorts=-created_at"];

   /**
    * isClientListing is used to determine if the listing should be for a single client or for all clients
    * If it's for all clients, we remove the client_id filter
    */
   if (!isClientListing) {
      filters.shift();
   }

   const onRowClick = (event: DataTableRowClickEvent) => {
      const url = getCampaignRecurrencesUrl({ user: user as any, campaign: event.data });
      navigate(url);
   };

   const cols: ColumnProps[] = [
      { field: "ref", header: "Référence" },
      { field: "type", header: "Type", className: "uppercase" },
      { field: "start_date", header: "Date de début", body: (e) => formatDate(e.start_date) },
      { field: "end_date", header: "Date de fin", body: (e) => formatDate(e.end_date) },
      { field: "total_recipients", header: "Envois" },
   ];

   return (
      <>
         <div>
            <div className="pb-8">
               <Filters />
            </div>

            <ResourceListing columns={cols} onRowClick={onRowClick} resource="campaigns" defaultFilters={filters} />
         </div>
      </>
   );
}
