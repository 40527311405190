import axios from "axios";
import { Cookie } from "hooks";

const cookie = Cookie("token");

const axiosInstance = axios.create({
   baseURL: `${process.env.REACT_APP_API_URL}/api`,
   headers: {
      Authorization: `Bearer ${cookie.value}`,
   },

   withCredentials: true,
});
export default axiosInstance;

export const axiosInstanceWithoutPrefix = axios.create({
   baseURL: `${process.env.REACT_APP_API_URL}`,
   headers: {
      Authorization: `Bearer ${cookie.value}`,
   },
   withCredentials: true,
});
