import React from "react";
import { RoutesEnum } from "Routes";
import { Pagination, SearchParamInput, Skeleton } from "components";
import { Link, useSearchParams } from "react-router-dom";
import { useCrud, useImmutable } from "hooks";
import { range } from "lodash";

export default function Listing() {
   const itemsPerPage = 8;
   let [searchParams] = useSearchParams();
   const query = searchParams.toString();
   const { data: immutableData } = useImmutable(`/clients`);
   const { data: finalData, isLoading } = useCrud<any>({ url: `/clients?per_page=${itemsPerPage}&sorts=-created_at&${query}` });

   const data: any[] = finalData ? finalData.data : [];
   const totalRecords = finalData?.meta.total ?? immutableData?.meta.total;

   return (
      <>
         <div className="flex gap-2 items-center justify-between">
            <SearchParamInput type="input" paramKey="search" />
            <Link
               to={`${RoutesEnum.NEW}`}
               className="text-secondary text-xs md:text-base bg-secondary/10 rounded-full px-5 py-1 pt-1.5 border border-secondary hover:bg-secondary hover:text-white transition"
            >
               Créer nouveau compte
            </Link>
         </div>
         <div>
            <h3 className="font-bold text-priamry text-lg py-4">Résultats</h3>
            <div className="">
               <ul className="grid gap-3">
                  {!isLoading
                     ? data.map((el) => (
                          <li key={el.id} className="grid md:grid-cols-2 py-1 md:py-3 border rounded-xl px-2 items-center">
                             <div className="grow text-lg"> {el.name} </div>
                             <div className="flex text-sm md:text-base justify-end  gap-2 md:gap-6">
                                <Link to={`/${RoutesEnum.CLIENTS}/${el.id}/${RoutesEnum.CAMPAIGNS}`} className="hover:opacity-80 text-primary">
                                   Voir compte
                                </Link>

                                <Link to={`/${RoutesEnum.CLIENTS}/${el.id}/${RoutesEnum.EDIT}`} className="hover:opacity-80 text-success">
                                   Modifier compte
                                </Link>
                             </div>
                          </li>
                       ))
                     : range(-1, itemsPerPage).map((el) => (
                          <div className="">
                             <div className="border rounded-xl p-3.5">
                                <Skeleton />
                             </div>
                          </div>
                       ))}
               </ul>
            </div>

            <Pagination className="mt-5" totalRecords={totalRecords} rows={itemsPerPage} />
         </div>
      </>
   );
}
