import { RoutesEnum } from "Routes";
import ResourceListing from "components/ResourceListing";
import { get } from "lodash";
import { ColumnProps } from "primereact/column";
import { DataTableRowClickEvent } from "primereact/datatable";
import { useNavigate, useParams } from "react-router-dom";
import { formatDate } from "services/utilities/date";

export default function Listing() {
   const { campaignId } = useParams();
   const navigate = useNavigate();

   const cols: ColumnProps[] = [
      { field: "ref", header: "Référence" },
      { field: "date_executed", header: "Date et heure", body: (row) => <>{formatDate(get(row, "date_executed"), "dd-MM-yyyy HH:mm")}</> },
      { field: "status", header: "Statut" },
      { field: "total_recipients", header: "Envois" },
   ];

   const onRowClick = (event: DataTableRowClickEvent) => {
      navigate(`${event.data.id}/${RoutesEnum.STATISTICS}`);
   };

   return (
      <ResourceListing
         resource="campaign-recurrences"
         columns={cols}
         onRowClick={onRowClick}
         defaultFilters={[`campaign_id=${campaignId}&sorts=-created_at`]}
      />
   );
}
