import "./App.css";
import { BrowserRouter } from "react-router-dom";
import AuthProvider from "features/auth/AuthProvider";
import { AppRoutes } from "./Routes";
import { ToastProvider } from "features/toast";

export default function App() {
   return (
      <ToastProvider>
         <BrowserRouter>
            <AuthProvider>
               <AppRoutes />
            </AuthProvider>
         </BrowserRouter>
      </ToastProvider>
   );
}
