import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { ReactComponent as Icon } from "assets/icons/magnifyingGlass.svg";
import { useSearchParams } from "react-router-dom";

interface Props {
   type: string;
   paramKey: string;
   value?: string;
   label?: string;
}
export default function SearchParamInput({ type, value, label, paramKey }: Props) {
   let [searchParams, setSearchParams] = useSearchParams();
   let [inputValue, setInputValue] = useState(() => {
      return searchParams.get(paramKey) ?? "";
   });

   const updateSearchParams = (value: string) => {
      if (value) {
         searchParams.set(paramKey, value);
         searchParams.delete("page");
      } else {
         searchParams.delete(paramKey);
      }
      setSearchParams(searchParams);
   };

   let timeoutBeforeUpdate = 0;
   if (type === "input") {
      timeoutBeforeUpdate = 800;
   }

   useEffect(() => {
      const timeOutId = setTimeout(() => {
         updateSearchParams(inputValue);
      }, timeoutBeforeUpdate);
      return () => clearTimeout(timeOutId);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [inputValue]);

   switch (type) {
      case "input":
         return (
            <span className="p-input-icon-left">
               <Icon className="h-4" />
               <InputText value={inputValue} onChange={(e) => setInputValue(e.target.value)} placeholder="Rechercher" className="rounded-lg w-full" />
            </span>
         );
      case "date":
         return (
            <input type="date" value={inputValue} onChange={(e) => setInputValue(e.target.value)} className="bg-grayLight/20 px-3 py-1 rounded-lg w-full" />
         );
      case "radio":
         return (
            <div>
               <input
                  id={label}
                  name="type"
                  type="radio"
                  checked={value === searchParams.get(paramKey)}
                  onChange={(e) => {
                     let isOn = e.target.checked;
                     console.log(e.target.checked);
                     updateSearchParams(isOn ? (value as string) : "");
                  }}
                  className="mr-2"
               />
               <label htmlFor={label}>{label}</label>
            </div>
         );

      default:
         return null;
   }
}
