import { RoutesEnum } from "Routes";
import logo from "assets/images/logo.png";
import { Footer } from "components";
import { Logout } from "features/auth";
import { NavLink, Outlet } from "react-router-dom";
export default function AdminLayout() {
   const menuItems = [
      {
         label: "Clients",
         path: RoutesEnum.CLIENTS,
      },
      {
         label: "Campagnes",
         path: RoutesEnum.CAMPAIGNS,
      },
      {
         label: "Paramètres",
         path: RoutesEnum.SETTINGS,
      },
   ];

   return (
      <div className="">
         <div className="container mx-auto flex gap-3 items-center justify-between py-4 px-3">
            <img src={logo} className="h-8" alt="" />
            <div className="flex items-center gap-3">
               <h3 className="text-lg">Portail Administrateur</h3>

               <Logout />
            </div>
         </div>
         <div className="bg-primaryLight">
            <ul className="text-white container mx-auto px-3 flex gap-5">
               {menuItems.map((item) => (
                  <li key={item.label}>
                     <NavLink
                        to={item.path}
                        className={({ isActive, isPending }) =>
                           "text-white pt-4 inline-block mb-4 " + (isPending ? "pending" : isActive ? "border-b border-white" : "")
                        }
                     >
                        {item.label}
                     </NavLink>
                  </li>
               ))}
            </ul>
         </div>
         <div className="container mx-auto px-3 py-5 min-h-[79dvh] md:min-h-[81dvh]">
            <Outlet />
         </div>
         <Footer />
      </div>
   );
}
