import React from "react";
import { InputTextProps } from "primereact/inputtext";
import { useField } from "formik";
import { classNames } from "services/utilities";
import ErrorMessage from "./ErrorMessage";

export default function Input({ name, className, label, hideStar = false, ...props }: InputTextProps & { name: string; label?: string; hideStar?: boolean }) {
   const [field, meta] = useField(name);
   const hasError = meta.error && meta.touched;

   return (
      <>
         {label && (
            <label htmlFor={name} className={classNames("text-semibold mb-1 inline-block", { "text-danger": hasError })}>
               {label}
               {!hideStar && "*"}
            </label>
         )}
         <input
            disabled={props.disabled}
            id={name}
            className={classNames(
               "p-inputtext py-1.5 w-full focus:bg-primary/[0.16] !focus:border-primary focus:shadow-primary text-sm md:text-base",
               { "has-error": hasError },
               className
            )}
            {...field}
            type={props.type}
            placeholder={props.placeholder}
         />
         <ErrorMessage hasError={!!hasError} error={meta.error} />
      </>
   );
}
