import { get } from "lodash";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import { Roles } from "./authService";
import { RoutesEnum } from "Routes";

export default function UserHomePage() {
   const { user } = useAuth();
   const userRole = get(user, "role.name", "");
   const homePagePaths = {
      [Roles.SUPER_ADMIN]: `/${RoutesEnum.CLIENTS}`,
      [Roles.CLIENT_ADMIN]: `/${RoutesEnum.MYSPACE}`,
   };
   const homePagePath = get(homePagePaths, userRole, "");
   return <Navigate to={homePagePath} />;
}
