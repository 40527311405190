import Cookies from "js-cookie";

export default function Cookie(name: string) {
   const value = Cookies.get(name);

   const set = (val: string) => {
      Cookies.set(name, val, { expires: 7 });
   };

   const remove = () => {
      Cookies.remove(name);
   };

   return { value, set, remove };
}
