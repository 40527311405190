import { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { AuthLayout, AdminLayout, ClientLayout } from "./layouts";
import { ProtectedRoute } from "features/auth";
import { Layout as SingleClientLayout } from "features/clients";
import { Layout as SingleCampaignLayout } from "features/campaigns";
import { RecurrenceLayout } from "features/campaign-recurrences";
import { Roles } from "features/auth/authService";
import { CAMPAIGN_TYPE } from "features/campaigns/campaignService";

const Login = lazy(() => import("./pages/auth/Login"));
const ForgotPassword = lazy(() => import("./pages/auth/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/auth/ResetPassword"));
const ClientsListing = lazy(() => import("./pages/clients/Index"));
const New = lazy(() => import("./pages/clients/New"));
const Edit = lazy(() => import("./pages/clients/Edit"));
const CampaignsListingPage = lazy(() => import("./pages/campaigns/Index"));
const CampaignsListingComponent = lazy(() => import("features/campaigns/components/Listing"));
const CampaignRecurrencesListingComponent = lazy(() => import("features/campaign-recurrences/components/Listing"));
const CampaignGeneralStatisticsComponent = lazy(() => import("features/campaigns/components/Statistics"));
const RecurrenceStatistics = lazy(() => import("features/campaign-recurrences/components/Statistics"));
const RecurrenceRecipients = lazy(() => import("features/campaign-recurrences/components/Recipients"));
const Senders = lazy(() => import("features/campaigns/components/Senders"));
const ClientUsersList = lazy(() => import("features/client-users/components/ClientUsersList"));
const NewEmailCampaign = lazy(() => import("pages/campaigns/NewEmailCampaign"));
const NewSmsCampaign = lazy(() => import("pages/campaigns/NewSmsCampaign"));
const EditCampaign = lazy(() => import("pages/campaigns/EditCampaign"));
const Settings = lazy(() => import("./pages/settings/Index"));
const ClientHome = lazy(() => import("./pages/my-space/Home"));
const CampaignsListingClientPage = lazy(() => import("./pages/my-space/Campaigns"));
const ReportsPage = lazy(() => import("./pages/my-space/Reports"));
const GenerateReportPage = lazy(() => import("./pages/my-space/GenerateReport"));
const ClientSupport = lazy(() => import("./pages/my-space/Support"));
const DownloadMyDocument = lazy(() => import("./pages/DownloadMyDocument"));
const DownloadMyDocumentViaShortLink = lazy(() => import("./pages/DownloadMyDocumentViaShortLink"));
const NotAuthorized = lazy(() => import("./pages/NotAuthorized"));
const NotFound = lazy(() => import("./pages/NotFound"));

export enum RoutesEnum {
   HOME = "clients",
   AUTH = "auth",
   LOGIN = "login",
   FORGOT_PASSWORD = "forgot-password",
   RESET_PASSWORD = "reset-password",
   NOT_AUTHORISZED = "not-authorized",
   MANIFESTS = "manifests",
   NEW = "new",
   EDIT = "edit",
   CLIENTS = "clients",
   CAMPAIGNS = "campaigns",
   SETTINGS = "settings",
   SENDERS = "senders",
   USERS = "users",
   GENERAL_STATISTICS = "general-statistics",
   RECURRENCES = "recurrences",
   STATISTICS = "statistics",
   RECIPIENTS = "recipients",
   MYSPACE = "my-space",
   REPORTS = "reports",
   GENERATE_REPORT = "generate-report",
   SUPPORT = "support",
   WELCOME = "welcome",
   DOWNLOAD_MY_DOCUMENT = "download-my-document",
}

export function AppRoutes() {
   return (
      <Routes>
         <Route path={RoutesEnum.AUTH} element={<AuthLayout />}>
            <Route
               path={RoutesEnum.LOGIN}
               element={
                  <Suspense>
                     <Login />
                  </Suspense>
               }
            />
            <Route
               path={RoutesEnum.FORGOT_PASSWORD}
               element={
                  <Suspense>
                     <ForgotPassword />
                  </Suspense>
               }
            />
            <Route
               path={RoutesEnum.RESET_PASSWORD}
               element={
                  <Suspense>
                     <ResetPassword />
                  </Suspense>
               }
            />
         </Route>
         {/* admin routes */}
         <Route
            path=""
            element={
               <ProtectedRoute roles={[Roles.SUPER_ADMIN]}>
                  <AdminLayout />
               </ProtectedRoute>
            }
         >
            <Route
               index
               element={
                  <Suspense>
                     <Navigate to={`/${RoutesEnum.CLIENTS}`} />
                  </Suspense>
               }
            />
            <Route
               path={RoutesEnum.CLIENTS}
               element={
                  <Suspense>
                     <ClientsListing />
                  </Suspense>
               }
            />
            <Route
               path={`${RoutesEnum.CLIENTS}/${RoutesEnum.NEW}`}
               element={
                  <Suspense>
                     <New />
                  </Suspense>
               }
            />
            <Route
               path={`${RoutesEnum.CLIENTS}/:clientId`}
               element={
                  <Suspense>
                     <SingleClientLayout />
                  </Suspense>
               }
            >
               <Route
                  path={`${RoutesEnum.EDIT}`}
                  element={
                     <Suspense>
                        <Edit />
                     </Suspense>
                  }
               />
               <Route
                  path={`${RoutesEnum.CAMPAIGNS}`}
                  element={
                     <Suspense>
                        <CampaignsListingComponent />
                     </Suspense>
                  }
               />
               <Route
                  path={`${RoutesEnum.CAMPAIGNS}/${RoutesEnum.NEW}`}
                  element={
                     <Suspense>
                        <NewEmailCampaign />
                     </Suspense>
                  }
               />

               <Route
                  path={`${RoutesEnum.CAMPAIGNS}/${RoutesEnum.NEW}/${CAMPAIGN_TYPE.EMAIL}`}
                  element={
                     <Suspense>
                        <NewEmailCampaign />
                     </Suspense>
                  }
               />
               <Route
                  path={`${RoutesEnum.CAMPAIGNS}/${RoutesEnum.NEW}/${CAMPAIGN_TYPE.SMS}`}
                  element={
                     <Suspense>
                        <NewSmsCampaign />
                     </Suspense>
                  }
               />
               <Route
                  path={`${RoutesEnum.CAMPAIGNS}/:campaignId`}
                  element={
                     <Suspense>
                        <SingleCampaignLayout />
                     </Suspense>
                  }
               >
                  <Route
                     path={`${RoutesEnum.EDIT}`}
                     element={
                        <Suspense>
                           <EditCampaign />
                        </Suspense>
                     }
                  />
                  <Route
                     path={`${RoutesEnum.RECURRENCES}`}
                     element={
                        <Suspense>
                           <CampaignRecurrencesListingComponent />
                        </Suspense>
                     }
                  />
                  <Route
                     path={`${RoutesEnum.GENERAL_STATISTICS}`}
                     element={
                        <Suspense>
                           <CampaignGeneralStatisticsComponent />
                        </Suspense>
                     }
                  />
                  <Route
                     path={`${RoutesEnum.RECURRENCES}/:recurrenceId`}
                     element={
                        <Suspense>
                           <RecurrenceLayout />
                        </Suspense>
                     }
                  >
                     <Route
                        index
                        element={
                           <Suspense>
                              <Navigate to={`${RoutesEnum.STATISTICS}`} />
                           </Suspense>
                        }
                     />
                     <Route
                        path={`${RoutesEnum.STATISTICS}`}
                        element={
                           <Suspense>
                              <RecurrenceStatistics />
                           </Suspense>
                        }
                     />
                     <Route
                        path={`${RoutesEnum.RECIPIENTS}`}
                        element={
                           <Suspense>
                              <RecurrenceRecipients />
                           </Suspense>
                        }
                     />
                  </Route>
               </Route>
               <Route
                  path={`${RoutesEnum.SENDERS}`}
                  element={
                     <Suspense>
                        <Senders />
                     </Suspense>
                  }
               />
               <Route
                  path={`${RoutesEnum.USERS}`}
                  element={
                     <Suspense>
                        <ClientUsersList />
                     </Suspense>
                  }
               />
            </Route>
            <Route
               path={RoutesEnum.CAMPAIGNS}
               element={
                  <Suspense>
                     <CampaignsListingPage />
                  </Suspense>
               }
            />
            <Route
               path={RoutesEnum.SETTINGS}
               element={
                  <Suspense>
                     <Settings />
                  </Suspense>
               }
            />
         </Route>

         {/* client routes */}
         <Route
            path={RoutesEnum.MYSPACE}
            element={
               <ProtectedRoute roles={[Roles.CLIENT_ADMIN, Roles.CLIENT_MANAGER]}>
                  <ClientLayout />
               </ProtectedRoute>
            }
         >
            <Route
               index
               element={
                  <Suspense>
                     <Navigate to={`${RoutesEnum.CAMPAIGNS}`} />
                  </Suspense>
               }
            />

            <Route
               path={RoutesEnum.WELCOME}
               element={
                  <Suspense>
                     <ClientHome />
                  </Suspense>
               }
            />

            <Route
               path={RoutesEnum.REPORTS}
               element={
                  <Suspense>
                     <ReportsPage />
                  </Suspense>
               }
            />
            <Route
               path={`${RoutesEnum.REPORTS}/${RoutesEnum.GENERATE_REPORT}`}
               element={
                  <Suspense>
                     <GenerateReportPage />
                  </Suspense>
               }
            />
            <Route
               path={RoutesEnum.CAMPAIGNS}
               element={
                  <Suspense>
                     <CampaignsListingClientPage />
                  </Suspense>
               }
            />

            <Route
               path={`${RoutesEnum.CAMPAIGNS}/:campaignId`}
               element={
                  <Suspense>
                     <SingleCampaignLayout />
                  </Suspense>
               }
            >
               <Route
                  path={`${RoutesEnum.RECURRENCES}`}
                  element={
                     <Suspense>
                        <CampaignRecurrencesListingComponent />
                     </Suspense>
                  }
               />
               <Route
                  path={`${RoutesEnum.GENERAL_STATISTICS}`}
                  element={
                     <Suspense>
                        <CampaignGeneralStatisticsComponent />
                     </Suspense>
                  }
               />
               <Route
                  path={`${RoutesEnum.RECURRENCES}/:recurrenceId`}
                  element={
                     <Suspense>
                        <RecurrenceLayout />
                     </Suspense>
                  }
               >
                  <Route
                     index
                     element={
                        <Suspense>
                           <Navigate to={`${RoutesEnum.STATISTICS}`} />
                        </Suspense>
                     }
                  />
                  <Route
                     path={`${RoutesEnum.STATISTICS}`}
                     element={
                        <Suspense>
                           <RecurrenceStatistics />
                        </Suspense>
                     }
                  />
                  <Route
                     path={`${RoutesEnum.RECIPIENTS}`}
                     element={
                        <Suspense>
                           <RecurrenceRecipients />
                        </Suspense>
                     }
                  />
               </Route>
            </Route>

            <Route
               path={RoutesEnum.SUPPORT}
               element={
                  <Suspense>
                     <ClientSupport />
                  </Suspense>
               }
            />
         </Route>

         {/* Download file for email campaigns */}
         <Route
            path={RoutesEnum.DOWNLOAD_MY_DOCUMENT}
            element={
               <Suspense>
                  <DownloadMyDocument />
               </Suspense>
            }
         />

         {/* download file form sms campaigns -short link- */}
         <Route
            path="sl/:shortLink"
            element={
               <Suspense>
                  <DownloadMyDocumentViaShortLink />
               </Suspense>
            }
         />

         <Route
            path={RoutesEnum.NOT_AUTHORISZED}
            element={
               <Suspense>
                  <NotAuthorized />
               </Suspense>
            }
         />

         <Route
            path="*"
            element={
               <Suspense>
                  <NotFound />
               </Suspense>
            }
         />
      </Routes>
   );
}
